import axios from 'axios';
import { Auth } from "@aws-amplify/auth";
import i18n from '../i18n';

import Logger from '../../shared/services/logger.service'

function uuid () {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function getCookie (name) {
  const pattern = RegExp(name + '=.[^;]*');
  const matched = document.cookie.match(pattern);
  if (matched) {
    const cookie = matched[0].split('=');
    return cookie[1];
  }
  return false;
}

const publicApi = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL
});
publicApi.interceptors.request.use(
  (config) => {
    return new Promise((resolve) => {
      Logger.send(config).catch((err) => console.error(err))
      if (!getCookie('userIdentifier')) {
        document.cookie = `userIdentifier=${uuid()};expires=Fri, 31 Dec 9999 23:59:59 GMT`;
      }
      config.headers['user-identifier'] = getCookie('userIdentifier');
      config.headers['Accept-Language'] = i18n.global.locale;
      resolve(config);
    });
  }
);

const adminApi = axios.create({
  baseURL: process.env.VUE_APP_ADMIN_API_BASE_URL
});

adminApi.interceptors.request.use(
  (config) => {
    return new Promise((resolve) => {
      Logger.send(config).catch((err) => console.error(err))
      config.headers['Accept-Language'] = i18n.global.locale;
      Auth.currentSession().then((session) => {
        if (session.getIdToken().getExpiration() < Math.round(+new Date() / 1000)) {
          Auth.currentAuthenticatedUser().then((user) => {
            user.refreshSession(session.getRefreshToken(), (error, data) => {
              if (error) {
                Auth.signOut().then(() => {
                  window.location = `${window.location.origin}/#/auth/sign-in`
                });
              } else {
                config.headers.Authorization = `Bearer ${data.getAccessToken().getJwtToken()}`;
                resolve(config);
              }
            });
          });
        } else {
          config.headers.Authorization = `Bearer ${session.getAccessToken().getJwtToken()}`;
          resolve(config);
        }
      })
        .catch((err) => {
          console.error(err)
          window.location = `${window.location.origin}/#/auth/sign-in`
        });
    });
  }
);

export default {
  install (app) {
    app.config.globalProperties.$axios = publicApi;
    app.config.globalProperties.$adminApi = adminApi;
  }
};

export const adminAPI = adminApi