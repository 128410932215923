export default function normalizeUser(user = {}) {
    return {
      email: user?.email,
      phoneNumber: user?.phoneNumber ?? '',
      name: user?.name ?? '',
      enableBusinessPay: user?.enableBusinessPay ?? false,
      familyName: user?.lastname ?? '',
      nickName: user?.nickName ?? '',
      iban: user?.iban ?? '',
      birthdate: user?.dob ?? '',
      country: user?.addressCountry ?? 'DE',
      city: user?.addressCity  ?? '',
      postalCode: user?.addressPostalCode ?? '',
      address: user?.addressLine1 ?? '',
      motivation: user?.motivationQuote ?? '',
      isSendEmailEnabled: user?.sendEmailEnabled ?? false,
      tipsSmsDisabled: user?.tipsSmsDisabled ?? false,
      onboarding: user?.onboarding  ?? false,
      disableIbanTransfer: user?.disableIbanTransfer  ?? true,
      ibanStatus: user?.ibanStatus  ?? false,
      photoUrl: user?.photoUrl  ?? '',
      picture: user?.photoUrl  ?? '',
      username: user?.username ?? '',
      sub: user?.username ?? '',
      accReference: user?.accReference ?? '',
      mitarbeiterid: '',
      checkPayoutThreshold: user?.checkPayoutThreshold  ?? true,
      privateInfo: user?.privateInfo ?? true,
      hasIban: !!(user.iban && user.iban.length),
      balance: {
        paidToday: 0,
        available: 0,
        paidWeekly: 0,
        reconcileAvailable: 0,
        reconciled: 0,
        inTransition: 0
      },
      disableAvatarUpload: user?.disableAvatarUpload ?? false,
      disableQrUnassign: user?.disableQrUnassign ?? false

    }
}