export default {
  "menu": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenten"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steun"])}
  },
  "auth": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
    "forgot-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten?"])},
    "generate-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord genereren"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord opnieuw instellen"])},
    "sign-in": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door in te loggen accepteer je de"])}
    },
    "sign-out": {
      "confirm-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je wilt uitloggen?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])}
    },
    "sign-up": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door een account te maken, accepteer je onze"])}
    },
    "user-terms-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["algemene voorwaarden"])}
  },
  "error-page": {
    "can-not-find-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We kunnen de pagina die je zoekt niet vinden."])}
  },
  "form": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toewijzen"])},
    "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar de startpagina"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigen"])},
    "confirm-unassign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wil je echt loskoppelen van het bedrijf ", _interpolate(_named("company")), "?"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gereed"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])},
    "item-approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " is goedgekeurd"])},
    "item-assigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " is toegewezen"])},
    "item-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " is gewijzigd"])},
    "item-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " is gemaakt"])},
    "item-deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " is verwijderd"])},
    "item-not-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sorry, we kunnen het niet wijzigen ", _interpolate(_named("item"))])},
    "item-not-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sorry, we kunnen het niet maken ", _interpolate(_named("item"))])},
    "item-not-found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " niet gevonden"])},
    "item-unassigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " is niet meer toegewezen"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw instellen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenden"])},
    "something-wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oeps, er is iets misgegaan"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geslaagd"])},
    "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toewijzing ongedaan maken"])},
    "validation": {
      "all-items-populated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle items moeten worden ingevuld"])},
      "avatar-format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avatarafbeelding moet ", _interpolate(_named("format")), "-opmaak hebben"])},
      "avatar-size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De grootte van de avatarfoto mag niet groter zijn dan ", _interpolate(_named("size"))])},
      "dont-much": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " komt niet overeen"])},
      "field-max-value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " kan niet groter zijn dan ", _interpolate(_named("max"))])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ongeldig ", _interpolate(_named("field")), " formaat"])},
      "incorrect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Onjuist ", _interpolate(_named("field"))])},
      "input-again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voer de ", _interpolate(_named("field")), " opnieuw in"])},
      "must-be-number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " moet een getal zijn"])},
      "one-item-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten minste één item moet standaard zijn"])},
      "one-item-filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er moet minimaal één veld zijn ingevuld"])},
      "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " is vereist"])},
      "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restbedrag moet 0 zijn"])}
    },
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijken"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
  },
  "opera": {
    "payment-methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschikbare betaalmethoden"])}
  },
  "presentation": {
    "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentatiemodus"])}
  },
  "fee": {
    "tippie-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippie betaalt de kosten als de gast niet betaalt"])},
    "organization-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ober betaalt altijd kosten"])},
    "amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " servicekosten ", _interpolate(_named("icon"))])},
    "checkbox": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Help ", _interpolate(_named("nickname")), " om je volledige fooi te krijgen"])},
    "dialog-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We rekenen minimale transactiekosten omdat er kosten aan de verwerking zijn verbonden. Door bij te dragen aan de dekking van deze kosten als onderdeel van je fooi, zorg je ervoor dat de ontvanger van de fooi het volledige bedrag ontvangt."])},
    "dialog-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])}
  },
  "organization": {
    "form": {
      "tabs": {
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemeen"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijzen"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])}
      }
    },
    "wanna-unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u de toewijzing ongedaan wilt maken?"])},
    "industry": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrie"])},
      "options": {
        "charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goed doel"])},
        "barber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapper"])},
        "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
        "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overig"])}
      }
    },
    "hide-paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide PayPal button if payer has Apple Pay or Google Pay"])},
    "be-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BE ID"])},
    "create-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisatie maken"])},
    "defined-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vooraf geselecteerd fooibedrag"])},
    "defined-percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorgeselecteerd percentage fooi van het ontvangstbedrag"])},
    "delete-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet je zeker dat je wilt verwijderen?"])},
    "edit-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisatie bewerken"])},
    "feedback-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback/evaluatie inschakelen"])},
    "google-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google-beoordeling ingeschakeld"])}
    },
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn organisaties"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "platform-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platformkosten %"])},
    "pre-selected-tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorgeselecteerde fooi"])},
    "qr-code-ranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-codebereiken"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representatief contactpersoon"])},
    "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles weergeven"])},
    "send-payment-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een e-mail met betalingsbevestiging verzenden?"])},
    "tax-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW-nummer"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Organisatie"]), _normalize(["Organisaties"])])},
    "trial-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde van proefperiode"])},
    "tripadvisor-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tripadvisor-beoordeling ingeschakeld"])}
    },
    "type-it-system": {
      "options": {
        "generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegenereerd"])},
        "plastic-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastic kaart"])},
        "stickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stickers"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type IT-systeem"])}
    },
    "type-sharing-tips": {
      "options": {
        "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individueel"])},
        "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pooling"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type fooien voor delen"])}
    },
    "pricingPayerPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing if payer pays fee"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed fee in EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage fee above threshold"])}
    },
    "pricingRecipientPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing if recipient pays fee"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed fee in EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage fee above threshold"])}
    },
    "pricingPayout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing for payout"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold for free payout in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee for payout below threshold in  EUR"])}
    }
  },
  "pay-out": {
    "no-iban-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voordat je geld kunt opnemen van je bankrekening, moet je je rekeninggegevens invoeren. Ga hiervoor naar \"Profiel bewerken\" in de app."])},
    "bank-statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Houd er rekening mee dat op uw bankafschrift de afzender van het geld wordt weergegeven als <b>\"tippie\"</b> of <b>\"Stripe\"</b> of <b>\"NaudaPay Limited\"</b>."])},
    "payout-fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De overdrachtskosten voor deze transactie bedragen ", _interpolate(_named("payoutFee"))])},
    "payout-fee-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Er worden geen kosten in rekening gebracht voor opnames van ", _interpolate(_named("payoutFeeThreshold")), " of meer."])},
    "check-payout-threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch uitbetalen bij drempel"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitbetalen"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig overschrijving naar IBAN"])},
    "equal-to-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelijk aan iedereen"])},
    "equal-to-remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelijk blijven"])},
    "error-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout! De transactie kon niet worden uitgevoerd. Neem contact op met de ondersteuning van Tippie"])},
    "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentages"])},
    "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restbedrag"])},
    "residual-percents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restpercentages"])},
    "strategy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconciliatiestrategie"])},
      "types": {
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complex"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simpel"])}
      }
    },
    "success-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dank je wel! Je ontvangt binnen enkele minuten een bevestigingsmail."])},
    "total-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale betaling"])},
    "to-reconcile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Af te stemmen bedrag"])}
  },
  "profile": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toewijzingen"])},
    "balance": {
      "balance-of-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo van je rekening:"])},
      "tips-last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fooien ontvangen in de afgelopen 7 dagen"])},
      "tips-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vandaag ontvangen fooien"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
      "transfer-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag voor overschrijving naar bankrekening:"])},
      "transfer-to-iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overschrijving naar IBAN"])},
      "your-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je saldo"])},
      "reconciled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fooien overgemaakt naar je bankrekening:"])},
      "inTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fooien verzonden naar je bankrekening:"])}
    },
    "change-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding wijzigen"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord wijzigen"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geboortedatum"])},
    "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel bewerken"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "iban-connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je IBAN is verbonden"])},
    "iban-contact-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact opnemen met de ondersteuning voor het oplossen van een probleem met IBAN"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
    "motivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivatie"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijnaam"])},
    "notification-emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldingen naar e-mail verzenden?"])},
    "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud wachtwoord"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
    "password-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het wachtwoord moet minstens 8 tekens lang zijn en minstens één kleine letter, één hoofdletter, één cijfer en één speciaal teken bevatten."])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
    "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "repeat-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord herhalen"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord herhalen"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thuis"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
    "send-tips-sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur een bevestiging van de tip via sms"])},
    "disable-payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schakel automatische uitbetaling uit"])},
    "user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersrol"])},
    "show-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werknemersrapporten tonen"])}
  },
  "qr-codes": {
    "can-not-confirm-assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we konden je toewijzing niet bevestigen"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-code maken"])},
    "statuses": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
      "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegewezen"])},
      "not-assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet toegewezen"])},
      "waiting-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachten op bevestiging"])}
    },
    "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je bent niet meer toegewezen"])}
  },
  "reports": {
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medewerkers"])},
    "empty": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer je zoekopdracht te wijzigen of selecteer een ander rapport"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is hier nog niets"])}
    },
    "filter": {
      "datepicker": {
        "last-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgelopen maand"])},
        "last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige week"])},
        "last-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorig jaar"])}
      },
      "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einddatum"])},
      "max-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximaal bedrag"])},
      "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begindatum"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
    },
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn organisaties"])},
    "outline-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitzonderlijke betalingen"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingen"])},
    "recent-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recente medewerkers"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport opslaan"])},
    "save-as": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Opslaan als ", _interpolate(_named("format"))])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Rapport"]), _normalize(["Rapporten"])])},
    "paid-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BETAALD"])},
    "paid-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UITBETAALD"])},
    "cumBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUM.BALANCE"])}
  },
  "table": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gegevens"])}
  },
  "team": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistieken"])},
    "create-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team maken"])},
    "delete-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team verwijderen"])},
    "edit-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team bewerken"])},
    "employees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Medewerker"]), _normalize(["Medewerkers"])])},
    "group-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-code van de groep"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwoordelijke contactpersoon"])},
    "team-exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Team met naam ", _interpolate(_named("name")), " bestaat al"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Team"]), _normalize(["Teams"])])},
    "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
    "showTeamName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon teamnaam in plaats van gebruikersnaam op de betalingspagina."])},
    "paypal-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deactiveer de PayPal-knop (en Klarna-knop) als de fooi minder dan ", _interpolate(_named("threshold")), " EUR bedraagt."])}
  },
  "top-bar": {
    "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pooling"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek organisaties, teams, of QR-code"])},
    "search-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])}
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Gebruiker"]), _normalize(["Gebruikers"])])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individueel"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle teams"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])}
  },
  "reset-cache": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe content beschikbaar"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vernieuwen"])}
  },
  "pwa-link": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tippie to Home screen"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippie added to home screen"])},
    "get-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang de gratis app"])}
  },
  "faq": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flossplatz 6, 04107 Leipzig, Duitsland"])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Auteursrecht © ", _interpolate(_named("date")), ". Alle rechten voorbehouden."])}
  },
  "generate-signup-link": {
    "iban-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met IBAN"])},
    "iban-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zonder IBAN"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medewerker"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenaar"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmeldingslink is succesvol gekopieerd!"])}
  }
}