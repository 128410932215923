import { createRouter, createWebHashHistory } from "vue-router";
import { Auth } from "@aws-amplify/auth";

const routes = [
  {
    path: "/",
    redirect: { name: "Dashboard" },
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../views/Auth/Base"),
    redirect: { name: "SignIn" },
    children: [
      {
        path: "sign-in",
        name: "SignIn",
        components: {
          Header: () => import("../views/Auth/components/Header"),
          Form: () => import("../views/Auth/templates/SignIn"),
        },
      },
      {
        path: "sign-up",
        name: "SignUp",
        components: {
          Header: () => import("../views/Auth/components/Header"),
          Form: () => import("../views/Auth/templates/SignUp"),
        },
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        components: {
          Header: () => import("../views/Auth/components/Header"),
          Form: () => import("../views/Auth/templates/ResetPassword"),
        },
      },
    ],
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard/DashboardBase"),
    redirect: { name: "MainPage" },
    children: [
      {
        path: "upload-documents",
        name: "Documents",
        component: () => import("../views/Dashboard/templates/DocumentsPage"),
        meta: { requiresAuth: true },
      },
      {
        path: "sign-up",
        name: "SignUpDashboard",
        component: () => import("../views/Auth/templates/SignUp"),
      },
      {
        path: "recommendation",
        name: "RecommendationPage",
        component: () => import("../views/Dashboard/templates/RecommendationPage"),
      },
      {
        path: "main",
        name: "MainPage",
        component: () => import("../views/Dashboard/templates/MainPage"),
        meta: { requiresAuth: true },
      },
      {
        path: "assignments",
        name: "Assignments",
        component: () => import("../views/Dashboard/templates/AssignmentsPage"),
        meta: { requiresAuth: true },
      },
      {
        path: "organizations",
        name: "Organizations",
        component: () =>
          import("../views/Dashboard/templates/OrganizationsPage"),
        meta: { requiresBusiness: true },
      },
      {
        path: "organizations/:organizationId",
        name: "Teams",
        component: () => import("../views/Dashboard/templates/TeamsPage"),
        meta: { requiresBusiness: true },
      },
      {
        path: "organizations/:organizationId/:teamId",
        name: "QrCodes",
        component: () => import("../views/Dashboard/templates/QrCodesPage"),
        meta: { requiresBusiness: true },
      },
      {
        path: "reports/:reportType",
        name: "Reports",
        component: () => import("../views/Dashboard/templates/ReportsPage"),
        meta: { requiresAuth: true },
      },
      {
        path: "pay-out",
        name: "PayOut",
        component: () => import("../views/Dashboard/templates/PayOutPage"),
        meta: { requiresTeamResp: true },
      },
      {
        path: "users/:nextToken(\\d+)?",
        name: "Users",
        component: () => import("../views/Dashboard/templates/AdminUserList"),
        meta: { requiresAdmin: true },
      },
      {
        path: "edit/faq",
        name: "EditFaq",
        component: () => import("../views/Dashboard/templates/EditFaq"),
        meta: { requiresAdmin: true },
      },
    ],
  },
  {
    path: "/user-terms",
    name: "UserTerms",
    component: () => import("../views/Terms/UserTerms"),
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("../views/Faq/Faq"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("../views/Errors/404"),
  },
];

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: "smooth",
          });
        }, 300);
      });
    } else {
      return { top: 0 };
    }
  },
});

export const routerSetup = {
  install(app) {
    router.install(app);
    router.beforeEach(async (to, from, next) => {
      if (to.matched.some((record) => record.meta.requiresAuth === true)) {
        Auth.currentAuthenticatedUser()
          .then(() => {
            next();
          })
          .catch(() => {
            next({ name: "SignIn" });
          });
      } else {
        next();
      }
      
      const roles = await app.config.globalProperties
        .currentUserRoles()
        .then((roles) => roles);

      const isAdmin = roles.includes("admin-group");
      const isTeamResp = roles.includes("team-representative");
      const isBusiness = roles.includes("business-representative");

      if (to.matched.some((record) => record.meta.requiresBusiness === true)) {
        if (!isAdmin && !isBusiness) {
          next({ name: "MainPage" });
        }
      } else if (
        to.matched.some((record) => record.meta.requiresAdmin === true)
      ) {
        if (!isAdmin) {
          next({ name: "MainPage" });
        }
      } else if (
        to.matched.some((record) => record.meta.requiresTeamResp === true)
      ) {
        if (!isTeamResp) {
          next({ name: "MainPage" });
        }
      }

    });
  },
};
