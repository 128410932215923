export default {
  "menu": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrati"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporto"])}
  },
  "auth": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice"])},
    "forgot-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password dimenticata?"])},
    "generate-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera password"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta password"])},
    "sign-in": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedendo accetti il"])}
    },
    "sign-out": {
      "confirm-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermi di voler uscire?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esci"])}
    },
    "sign-up": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrati"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creando un account, accetti i nostri"])}
    },
    "user-terms-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termini e condizioni"])}
  },
  "error-page": {
    "can-not-find-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non riusciamo a trovare la pagina che stai cercando."])}
  },
  "form": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegna"])},
    "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla homepage"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
    "confirm-unassign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vuoi davvero disconnetterti dalla compagnia ", _interpolate(_named("company")), "?"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
    "item-approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " è stato approvato"])},
    "item-assigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " è stato assegnato"])},
    "item-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " è stato modificato"])},
    "item-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " è stato creato"])},
    "item-deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " è stato eliminato"])},
    "item-not-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Spiacenti, non possiamo cambiare ", _interpolate(_named("item"))])},
    "item-not-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Siamo spiacenti, non possiamo creare ", _interpolate(_named("item"))])},
    "item-not-found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " non trovato"])},
    "item-unassigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["È stata annullata l’assegnazione di ", _interpolate(_named("item"))])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successivo"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristina"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedisci"])},
    "something-wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops, qualcosa è andato storto"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successo"])},
    "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla assegnazione"])},
    "validation": {
      "all-items-populated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti gli elementi devono essere popolati"])},
      "avatar-format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L’immagine dell’avatar deve essere nel formato ", _interpolate(_named("format"))])},
      "avatar-size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La dimensione dell’immagine dell’avatar non può superare ", _interpolate(_named("size"))])},
      "dont-much": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " non corrisponde"])},
      "field-max-value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " non può essere maggiore di ", _interpolate(_named("max"))])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Formato ", _interpolate(_named("field")), " non valido"])},
      "incorrect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sbagliato ", _interpolate(_named("field"))])},
      "input-again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Si prega di inserire ", _interpolate(_named("field")), " nuovamente"])},
      "must-be-number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " deve essere un numero"])},
      "one-item-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almeno un elemento deve essere predefinito"])},
      "one-item-filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve essere compilato almeno un campo"])},
      "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " è obbligatorio"])},
      "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’importo residuo deve essere 0"])}
    },
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])}
  },
  "opera": {
    "payment-methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metodi‧di‧pagamento‧disponibili"])}
  },
  "presentation": {
    "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalità di presentazione"])}
  },
  "fee": {
    "tippie-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippie paga i costi se l'ospite non paga"])},
    "organization-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cameriere paga sempre la tariffa"])},
    "amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " costo del servizio ", _interpolate(_named("icon"))])},
    "checkbox": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aiuta ", _interpolate(_named("nickname")), " a ottenere la tua mancia per intero"])},
    "dialog-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addebitiamo una piccola commissione di transazione perché ci sono costi coinvolti nell'elaborazione. Contribuendo a coprire questi costi come parte della mancia, ti assicuri che il destinatario della mancia riceva l'intero importo."])},
    "dialog-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])}
  },
  "organization": {
    "form": {
      "tabs": {
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generale"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzi"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])}
      }
    },
    "wanna-unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler annullare l'assegnazione?"])},
    "industry": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settore"])},
      "options": {
        "charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficenza"])},
        "barber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salone per parrucchiere"])},
        "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
        "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ristorante"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])}
      }
    },
    "hide-paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi il pulsante PayPal se il pagatore ha Apple Pay o Google Pay"])},
    "be-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID BE"])},
    "create-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea organizzazione"])},
    "defined-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo suggerimento preselezionato"])},
    "defined-percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggerimento‧percentuale‧pre-selezionato‧dall’importo‧della‧ricevuta"])},
    "delete-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare?"])},
    "edit-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica organizzazione"])},
    "feedback-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abilita feedback/valutazione"])},
    "google-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recensione Google abilitata"])}
    },
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mie organizzazioni"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "platform-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tariffa di piattaforma %"])},
    "pre-selected-tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggerimento preselezionato"])},
    "qr-code-ranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalli codice QR"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatto del rappresentante"])},
    "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedi tutto"])},
    "send-payment-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare e-mail di conferma del pagamento?"])},
    "tax-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Fiscale"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Organizzazione"]), _normalize(["Organizzazioni"])])},
    "trial-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine del periodo di prova"])},
    "tripadvisor-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recensione Tripadvisor abilitata"])}
    },
    "type-it-system": {
      "options": {
        "generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generato"])},
        "plastic-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta di plastica"])},
        "stickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adesivi"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di sistema informatico"])}
    },
    "type-sharing-tips": {
      "options": {
        "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuale"])},
        "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raggruppato"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di suggerimenti per la condivisione"])}
    },
    "pricingPayerPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing if payer pays fee"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed fee in EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage fee above threshold"])}
    },
    "pricingRecipientPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing if recipient pays fee"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed fee in EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage fee above threshold"])}
    },
    "pricingPayout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing for payout"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold for free payout in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee for payout below threshold in  EUR"])}
    }
  },
  "pay-out": {
    "no-iban-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima di poter effettuare un prelievo sul proprio conto bancario, è necessario inserire i dati del proprio conto. A tale scopo, cliccare su \"Modifica profilo\" nell'app."])},
    "bank-statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di notare che il tuo estratto conto bancario mostrerà il mittente del denaro come <b>\"tippie\"</b> o <b>\"Stripe\"</b> o <b>\"NaudaPay Limited\"</b>."])},
    "payout-fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La commissione di trasferimento per questa transazione è ", _interpolate(_named("payoutFee"))])},
    "payout-fee-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Non viene addebitata alcuna commissione per i prelievi di ", _interpolate(_named("payoutFeeThreshold")), " o più."])},
    "check-payout-threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento automatico alla soglia"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma il trasferimento all’IBAN"])},
    "equal-to-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uguale a tutti"])},
    "equal-to-remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uguale al resto"])},
    "error-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore! Impossibile eseguire la transazione. Contatta l’assistenza Tippie"])},
    "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentuali"])},
    "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo residuo"])},
    "residual-percents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentuali residue"])},
    "strategy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategia di riconciliazione"])},
      "types": {
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complesso"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semplice"])}
      }
    },
    "success-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie! Riceverai un’email di conferma in pochi minuti."])},
    "total-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retribuzione totale"])}
  },
  "profile": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incarichi"])},
    "balance": {
      "balance-of-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo del tuo conto:"])},
      "tips-last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggerimenti ricevuti negli ultimi 7 giorni"])},
      "tips-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggerimenti ricevuti oggi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
      "transfer-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo per il bonifico sul conto bancario:"])},
      "transfer-to-iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento su IBAN"])},
      "your-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo saldo"])},
      "reconciled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mance trasferite sul tuo conto bancario:"])},
      "inTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mance in fase di transizione verso il tuo conto bancario:"])}
    },
    "change-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia immagine"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia password"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazione"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
    "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica profilo"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "iban-connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo IBAN è collegato"])},
    "iban-contact-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatta il supporto per risolvere il problema dell’IBAN"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "motivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivazione"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soprannome"])},
    "notification-emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inviare notifiche via e-mail?"])},
    "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecchia password"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve essere lunga almeno 8 caratteri e contenere almeno una lettera minuscola, una lettera maiuscola, un numero e un carattere speciale."])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C.A.P."])},
    "repeat-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti nuova password"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripeti la password"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maison"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
    "send-tips-sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia la conferma della mancia via SMS"])},
    "disable-payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabilita il pagamento automatico"])},
    "user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo utente"])},
    "show-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra i rapporti dei dipendenti"])}
  },
  "qr-codes": {
    "can-not-confirm-assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siamo spiacenti, non siamo riusciti a confermare il tuo incarico"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea codice QR"])},
    "statuses": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto"])},
      "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegnato"])},
      "not-assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non assegnato"])},
      "waiting-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa di conferma"])}
    },
    "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti è stato tolto l’incarico"])}
  },
  "reports": {
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dipendenti"])},
    "empty": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova a modificare la tua ricerca o seleziona un altro rapporto"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non c'è ancora niente qui."])}
    },
    "filter": {
      "datepicker": {
        "last-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mese scorso"])},
        "last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settimana scorsa"])},
        "last-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anno scorso"])}
      },
      "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di fine"])},
      "max-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo massimo"])},
      "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di inizio"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])}
    },
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mie organizzazioni"])},
    "outline-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti anomali"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamenti"])},
    "recent-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dipendenti recenti"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva rapporto"])},
    "save-as": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Salva con nome ", _interpolate(_named("format"))])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Rapporto"]), _normalize(["Rapporti"])])},
    "paid-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL PAGATO"])},
    "paid-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAGATO"])},
    "cumBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUM.BALANCE"])}
  },
  "table": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun dato"])}
  },
  "team": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiche"])},
    "create-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea team"])},
    "delete-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina team"])},
    "edit-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica team"])},
    "employees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Dipendente"]), _normalize(["Dipendenti"])])},
    "group-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice QR di gruppo"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatto responsabile"])},
    "team-exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il team con nome ", _interpolate(_named("name")), " esiste già"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Team"]), _normalize(["Team"])])},
    "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "showTeamName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra il nome della squadra invece del nome utente nella pagina di pagamento."])},
    "paypal-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Disattivare il pulsante PayPal (e Klarna) se la mancia è inferiore a ", _interpolate(_named("threshold")), " euro."])},
    "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sei stato assegnato"])}
  },
  "top-bar": {
    "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raggruppato"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca organizzazioni, team, o codice QR"])},
    "search-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])}
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Utente"]), _normalize(["Utenti"])])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuale"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le squadre"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Squadra"])}
  },
  "pwa-link": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tippie to Home screen"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippie added to home screen"])},
    "get-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica l'applicazione gratuita"])}
  },
  "faq": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flossplatz 6, 04107 Lipsia, Germania"])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copyright © ", _interpolate(_named("date")), ". Tutti i diritti riservati."])}
  },
  "generate-signup-link": {
    "iban-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con IBAN"])},
    "iban-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senza IBAN"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dipendente"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietario"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il link di registrazione è stato copiato con successo!"])}
  }
}