<template>
  <div class="body-section-collapse">
    <div class="body-section-collapse-row">
      <el-upload
        class="body-section-collapse-row-icon-uploader"
        :show-file-list="false"
        :auto-upload="false"
        :limit="1"
        :on-change="handleIconUpload"
      >
        <img v-if="collapseItem.icon" :src="collapseItem.icon" class="body-section-collapse-row-icon-uploader__avatar"
             alt="Icon" />
        <el-icon v-else class="body-section-collapse-row-icon-uploader__icon">
          <Plus />
        </el-icon>
      </el-upload>
    </div>
    <div class="body-section-collapse-row">
      <label class="body-section-collapse-row__label">Anchor</label>
      <el-input v-model="collapseItem.anchor" />
    </div>
    <div class="body-section-collapse-row">
      <label class="body-section-collapse-row__label">Name</label>
      <el-input v-model="collapseItem.name" />
    </div>
    <div class="body-section-collapse-row">
      <label class="body-section-collapse-row__label">Title</label>
      <el-input v-model="collapseItem.title" />
    </div>
    <div class="body-section-collapse-row">
      <label class="body-section-collapse-row__label">Content</label>
      <vue-editor v-model="collapseItem.content" />
    </div>
    <div class="body-section-collapse-row">
      <label class="body-section-collapse-row__label">Child collapse</label>
      <vue-repeater v-model="collapseItem.childCollapseItems" />
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue3-editor';
import VueRepeater from '@penguin3007/vue3-repeater';

export default {
  name: 'TopSectionCollapse',
  components: {
    VueEditor,
    VueRepeater
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  computed: {
    collapseItem: {
      get: function () {
        return this.modelValue;
      },
      set: function (value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    handleIconUpload (file) {
      const isCorrectType = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif';
      const sizeLimit = file.size / 1024 / 1024 < 5;

      if (!isCorrectType) {
        this.$message.error(this.$t('form.validation.avatar-format', { format: 'JPEG, PNG' }));
        return false;
      }
      if (!sizeLimit) {
        this.$message.error(this.$t('form.validation.avatar-size', { size: '5MB' }));
        return false;
      }
      const self = this;
      const reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = function () {
        self.collapseItem.icon = reader.result;
      };
    }
  }
};
</script>

<style scoped lang="scss">
.body-section-collapse {
  &-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &__label {
      font-family: "Raleway SemiBold", serif;
      font-size: 16px;
      color: #2D2E2F;
    }

    &-icon-uploader {
      height: 100%;
      width: 80px;
      display: block;

      &__avatar {
        width: 100px;
        height: 100px;
        display: block;
      }

      &__icon {
        font-size: 28px;
        color: #8c939d;
        width: 60px;
        height: 60px;
        text-align: center;
      }

      &:deep(.el-upload) {
        border: 1px dashed var(--el-border-color);
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: var(--el-transition-duration-fast);

        &:hover {
          border-color: var(--el-color-primary);
        }
      }
    }
  }
}
</style>
