export default {
  "menu": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance"])}
  },
  "auth": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "forgot-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
    "generate-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer un mot de passe"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
    "sign-in": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En vous connectant, vous acceptez les"])}
    },
    "sign-out": {
      "confirm-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir vous déconnecter ?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])}
    },
    "sign-up": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En créant un compte, vous acceptez nos"])}
    },
    "user-terms-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conditions générales"])}
  },
  "error-page": {
    "can-not-find-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne trouvons pas la page que vous cherchez."])}
  },
  "form": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuer"])},
    "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la page d’accueil"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "confirm-unassign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voulez-vous vraiment vous déconnecter de l'entreprise ", _interpolate(_named("company")), "?"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édit"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
    "item-approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " a été approuvé"])},
    "item-assigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " a été affecté"])},
    "item-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " a été modifié"])},
    "item-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " a été créé"])},
    "item-deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " a été supprimé"])},
    "item-not-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Désolé, nous ne pouvons pas modifier ", _interpolate(_named("item"))])},
    "item-not-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Désolé, nous ne pouvons pas créer ", _interpolate(_named("item"))])},
    "item-not-found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " introuvable"])},
    "item-unassigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " n’est plus affecté"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "something-wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups, une erreur s’est produite"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
    "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l’affectation"])},
    "validation": {
      "all-items-populated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les éléments doivent être remplis"])},
      "avatar-format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le format de l’avatar doit être ", _interpolate(_named("format"))])},
      "avatar-size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La taille de l'image de l’avatar ne peut pas dépasser ", _interpolate(_named("size"))])},
      "dont-much": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " ne correspond pas"])},
      "field-max-value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " ne peut pas être supérieur à ", _interpolate(_named("max"))])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Format ", _interpolate(_named("field")), " non valide"])},
      "incorrect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " incorrect"])},
      "input-again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Veuillez ressaisir ", _interpolate(_named("field"))])},
      "must-be-number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " doit être un nombre"])},
      "one-item-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un élément doit être par défaut"])},
      "one-item-filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un champ doit être renseigné"])},
      "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " est obligatoire"])},
      "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant résiduel doit être de 0"])}
    },
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])}
  },
  "opera": {
    "payment-methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modes de paiement disponibles"])}
  },
  "presentation": {
    "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de présentation"])}
  },
  "fee": {
    "tippie-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippie paie des frais si le client ne paie pas"])},
    "organization-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le serveur paie toujours des frais"])},
    "amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" ", _interpolate(_named("amount")), " frais de service ", _interpolate(_named("icon"))])},
    "checkbox": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aidez ", _interpolate(_named("nickname")), " à recevoir la totalité de votre pourboire"])},
    "dialog-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous facturons de petits frais de transaction en raison des coûts qu’entraîne le traitement. En contribuant à couvrir ces coûts lorsque vous donnez un pourboire, vous vous assurez que le bénéficiaire du pourboire en reçoit la totalité."])},
    "dialog-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])}
  },
  "organization": {
    "form": {
      "tabs": {
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarification"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])}
      }
    },
    "wanna-unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment annuler l'attribution?"])},
    "industry": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
      "options": {
        "charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charité"])},
        "barber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coiffeur"])},
        "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôtel"])},
        "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
      }
    },
    "hide-paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer le bouton PayPal si le payeur a Apple Pay ou Google Pay"])},
    "be-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID BE"])},
    "create-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une organisation"])},
    "defined-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant de commission présélectionné"])},
    "defined-percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourcentage de commission présélectionné sur le montant du reçu"])},
    "delete-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous réellement supprimer ?"])},
    "edit-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'organisation"])},
    "feedback-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer les commentaires/l'évaluation"])},
    "google-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis Google activés"])}
    },
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes organisations"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "platform-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de plateforme %"])},
    "pre-selected-tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourboire présélectionné"])},
    "qr-code-ranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plages de codes QR"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact du représentant"])},
    "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
    "send-payment-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un e-mail de confirmation de paiement ?"])},
    "tax-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant fiscal"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Organisation"]), _normalize(["Organisations"])])},
    "trial-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de la période d’essai"])},
    "tripadvisor-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis Tripadvisor activés"])}
    },
    "type-it-system": {
      "options": {
        "generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Généré"])},
        "plastic-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte plastique"])},
        "stickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autocollants"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de système informatique"])}
    },
    "type-sharing-tips": {
      "options": {
        "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuel"])},
        "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regroupement"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de pourboires partagés"])}
    },
    "pricingPayerPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing if payer pays fee"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed fee in EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage fee above threshold"])}
    },
    "pricingRecipientPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing if recipient pays fee"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed fee in EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage fee above threshold"])}
    },
    "pricingPayout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing for payout"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold for free payout in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee for payout below threshold in  EUR"])}
    }
  },
  "pay-out": {
    "no-iban-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant de pouvoir effectuer un retrait sur ton compte bancaire, tu dois enregistrer les données du compte. Pour ce faire, va dans l'application et clique sur \"Modifier le profil\"."])},
    "bank-statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note que sur ton relevé de compte, l'expéditeur de l'argent apparaîtra comme <b>\"tippie\"</b> ou <b>\"Stripe\"</b> ou <b>\"NaudaPay Limited\"</b>."])},
    "payout-fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Les frais de transfert pour cette transaction sont ", _interpolate(_named("payoutFee"))])},
    "payout-fee-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aucun frais n'est facturé pour les retraits de ", _interpolate(_named("payoutFeeThreshold")), " ou plus."])},
    "check-payout-threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement automatique au seuil"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le transfert vers l'IBAN"])},
    "equal-to-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Égal à tous"])},
    "equal-to-remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Égal au reste"])},
    "error-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur ! La transaction n'a pas pu être effectuée. Veuillez contacter l'assistance Tippie"])},
    "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourcentages"])},
    "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant résiduel"])},
    "residual-percents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourcentages résiduels"])},
    "strategy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stratégie de réconciliation"])},
      "types": {
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complexe"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple"])}
      }
    },
    "success-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci ! Vous rallez recevoir un e-mail de confirmation dans quelques minutes."])},
    "total-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire total"])}
  },
  "profile": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectations"])},
    "balance": {
      "balance-of-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde de votre compte :"])},
      "tips-last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissions perçues au cours des 7 derniers jours"])},
      "tips-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourboires reçus aujourd’hui"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde"])},
      "transfer-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à virer sur compte bancaire :"])},
      "transfer-to-iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer vers IBAN"])},
      "your-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre solde"])},
      "reconciled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourboires transférés sur votre compte bancaire :"])},
      "inTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourboires en cours de transfert vers votre compte bancaire:"])}
    },
    "change-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l’image"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le profil"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "iban-connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre IBAN est connecté"])},
    "iban-contact-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacter le support pour résoudre un problème d’IBAN"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "motivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pseudo"])},
    "notification-emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer des notifications par e-mail ?"])},
    "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien mot de passe"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "password-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter au moins 8 caractères et contenir au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial."])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "repeat-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le nouveau mot de passe"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le mot de passe"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maison"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "send-tips-sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer la confirmation du pourboire par SMS"])},
    "disable-payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver le paiement automatique"])},
    "user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle utilisateur"])},
    "show-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les rapports des employés"])}
  },
  "qr-codes": {
    "can-not-confirm-assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, nous n'avons pas pu confirmer votre affectation"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un code QR"])},
    "statuses": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes"])},
      "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affecté"])},
      "not-assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non affecté"])},
      "waiting-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de confirmation"])}
    },
    "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été désaffecté(e)"])}
  },
  "reports": {
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employés"])},
    "empty": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayez de modifier votre recherche ou sélectionnez un autre rapport"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a encore rien ici"])}
    },
    "filter": {
      "datepicker": {
        "last-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mois dernier"])},
        "last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine dernière"])},
        "last-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année dernière"])}
      },
      "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
      "max-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant max"])},
      "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])}
    },
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes organisations"])},
    "outline-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements exceptionnels"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements"])},
    "recent-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employés récents"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le rapport"])},
    "save-as": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enregistrer sous ", _interpolate(_named("format"))])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Rapport"]), _normalize(["Rapports"])])},
    "paid-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL PAYÉ"])},
    "paid-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAYÉ"])},
    "cumBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUM.BALANCE"])}
  },
  "table": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de données"])}
  },
  "team": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])},
    "create-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une équipe"])},
    "delete-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l’équipe"])},
    "edit-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'équipe"])},
    "employees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Employé"]), _normalize(["Employés"])])},
    "group-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code QR de groupe"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact du responsable"])},
    "team-exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une équipe portant le nom ", _interpolate(_named("name")), " existe déjà"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Équipe"]), _normalize(["Équipes"])])},
    "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "showTeamName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le nom de l'équipe au lieu du nom d'utilisateur sur la page de paiement."])},
    "paypal-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Désactiver le bouton PayPal (et Klarna) si le pourboire est inférieur à ", _interpolate(_named("threshold")), " EUR."])},
    "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été désaffecté"])}
  },
  "top-bar": {
    "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regroupement"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher des organisations, des équipes, ou un code QR"])},
    "search-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])}
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Utilisateur"]), _normalize(["Utilisateurs"])])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuel"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les équipes"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipe"])}
  },
  "pwa-link": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tippie to Home screen"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippie added to home screen"])},
    "get-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger l' application gratuite"])}
  },
  "faq": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flossplatz 6, 04107 Leipzig, Allemagne"])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copyright © ", _interpolate(_named("date")), ". Tous les droits sont réservés."])}
  },
  "generate-signup-link": {
    "iban-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec IBAN"])},
    "iban-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans IBAN"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employé"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien d'inscription a été copié avec succès!"])}
  }
}