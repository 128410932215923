export default {
  "menu": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentos"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte"])}
  },
  "auth": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
    "forgot-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Has olvidado tu contraseña?"])},
    "generate-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar contraseña"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer contraseña"])},
    "sign-in": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al iniciar sesión, aceptas los"])}
    },
    "sign-out": {
      "confirm-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Seguro que deseas cerrar sesión?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cierre de sesión"])}
    },
    "sign-up": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al crear una cuenta, aceptas nuestros"])}
    },
    "user-terms-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["términos y condiciones"])}
  },
  "error-page": {
    "can-not-find-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parece que no podemos encontrar la página que estás buscando."])}
  },
  "form": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignar"])},
    "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a la página de inicio"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canc."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
    "confirm-unassign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿De verdad quieres desconectarte de la compañía ", _interpolate(_named("company")), "?"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elim."])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hecho"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ed."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "item-approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " se ha aprobado"])},
    "item-assigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " se ha asignado"])},
    "item-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " se ha cambiado"])},
    "item-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " se ha creado"])},
    "item-deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " se ha eliminado"])},
    "item-not-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lo sentimos, no podemos cambiar ", _interpolate(_named("item"))])},
    "item-not-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lo sentimos, no podemos crear ", _interpolate(_named("item"))])},
    "item-not-found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " no se ha encontrado"])},
    "item-unassigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " se ha desasignado"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gua."])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "something-wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaya, algo ha salido mal."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correcto"])},
    "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desasignar"])},
    "validation": {
      "all-items-populated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los elementos deben rellenarse"])},
      "avatar-format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La imagen del avatar debe tener el formato ", _interpolate(_named("format"))])},
      "avatar-size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El tamaño de la imagen del avatar no puede exceder ", _interpolate(_named("size"))])},
      "dont-much": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " no coincide"])},
      "field-max-value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " no puede ser mayor que ", _interpolate(_named("max"))])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Formato ", _interpolate(_named("field")), " no válido"])},
      "incorrect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " incorrecto"])},
      "input-again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Introduce ", _interpolate(_named("field")), " de nuevo"])},
      "must-be-number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " debe ser un número"])},
      "one-item-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos un elemento debe ser predeterminado"])},
      "one-item-filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe rellenarse al menos un campo"])},
      "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " es obligatorio"])},
      "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad restante debe ser 0"])}
    },
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])}
  },
  "opera": {
    "payment-methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de pago disponibles"])}
  },
  "presentation": {
    "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo presentación"])}
  },
  "fee": {
    "tippie-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippie paga tarifas si el invitado no pa"])},
    "organization-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El camarero siempre paga la tarifa."])},
    "amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " cargo por servicio ", _interpolate(_named("icon"))])},
    "checkbox": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ayuda ", _interpolate(_named("nickname")), " obtener su propina en su totalidad"])},
    "dialog-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cobramos una pequeña tarifa de transacción porque hay costos involucrados en el procesamiento. Al contribuir a cubrir estos costos como parte de su propina, se asegura de que el destinatario de la propina reciba el monto total."])},
    "dialog-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])}
  },
  "organization": {
    "form": {
      "tabs": {
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes"])}
      }
    },
    "wanna-unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que deseas cancelar la asignación?"])},
    "industry": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industria"])},
      "options": {
        "charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caridad"])},
        "barber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peluquero"])},
        "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
        "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurante"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otro"])}
      }
    },
    "hide-paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar el botón de PayPal si el pagador tiene Apple Pay o Google Pay"])},
    "be-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de BE"])},
    "create-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una organización"])},
    "defined-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de propina preseleccionada"])},
    "defined-percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje de propina preseleccionado del importe del recibo"])},
    "delete-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Seguro que deseas eliminarlo?"])},
    "edit-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar organización"])},
    "feedback-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar comentarios/evaluación"])},
    "google-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reseña de Google habilitada"])}
    },
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis organizaciones"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom."])},
    "platform-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% de tarifa de la plataforma"])},
    "pre-selected-tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propina preseleccionada"])},
    "qr-code-ranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rangos de códigos QR"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto del representante"])},
    "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todo"])},
    "send-payment-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Enviar correo electrónico de confirmación de pago?"])},
    "tax-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación fiscal"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Organización"]), _normalize(["Organizaciones"])])},
    "trial-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin del periodo de prueba"])},
    "tripadvisor-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reseña de Tripadvisor habilitada"])}
    },
    "type-it-system": {
      "options": {
        "generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generado"])},
        "plastic-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de plástico"])},
        "stickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pegatinas"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de sistema informático"])}
    },
    "type-sharing-tips": {
      "options": {
        "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
        "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrupación"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de propinas para compartir"])}
    },
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "pricingPayerPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios si el pagador paga la tarifa"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa fija en EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbral en EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de porcentaje por encima del umbral"])}
    },
    "pricingRecipientPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios si el destinatario paga la tarifa"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa fija en EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbral en EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasa de porcentaje por encima del umbral"])}
    },
    "pricingPayout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing for payout"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbral para pago gratuito en EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión por pago por debajo del umbral en EUR"])}
    }
  },
  "pay-out": {
    "no-iban-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antes de que pueda realizar un pago a su cuenta bancaria, debe ingresar los detalles de la cuenta. Para hacer esto, vaya a \"Editar perfil\" en la aplicación."])},
    "bank-statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenga en cuenta que su extracto bancario mostrará el remitente del dinero como <b>\"tippie\"</b> o <b>\"Stripe\"</b> o <b>\"NaudaPay Limited\"</b>."])},
    "payout-fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La tarifa de transferencia para esta transacción es ", _interpolate(_named("payoutFee"))])},
    "payout-fee-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No se cobra comisión por retiros de ", _interpolate(_named("payoutFeeThreshold")), " o más."])},
    "check-payout-threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar automáticamente en el umbral"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar transferencia al IBAN"])},
    "equal-to-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igual para todos"])},
    "equal-to-remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igual al restante"])},
    "error-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error No se ha podido completar la transacción. Contacta con el servicio de asistencia de Tippie"])},
    "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentajes"])},
    "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe restante"])},
    "residual-percents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentajes restantes"])},
    "strategy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estrategia de conciliación"])},
      "types": {
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complejo"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sencillo"])}
      }
    },
    "success-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Gracias! Recibirás un correo electrónico de confirmación en unos minutos."])},
    "total-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago total"])}
  },
  "profile": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignaciones"])},
    "balance": {
      "balance-of-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo de tu cuenta:"])},
      "tips-last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propinas recibidas durante los últimos 7 días"])},
      "tips-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propinas recibidas hoy"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
      "transfer-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe para transferir a la cuenta bancaria:"])},
      "transfer-to-iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia al IBAN"])},
      "your-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu saldo"])},
      "reconciled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propinas transferidas a tu cuenta bancaria:"])},
      "inTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propinas en camino a tu cuenta bancaria:"])}
    },
    "change-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar imagen"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Municipio"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
    "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar perfil"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "iban-connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu IBAN está conectado"])},
    "iban-contact-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacta con el servicio de asistencia para resolver el problema con el IBAN"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "motivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivación"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apodo"])},
    "notification-emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Enviar notificaciones por correo electrónico?"])},
    "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña anterior"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "password-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña debe tener al menos 8 caracteres y contener como mínimo una letra minúscula, una mayúscula, un número y un carácter especial."])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
    "repeat-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir nueva contraseña"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir contraseña"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogar"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "send-tips-sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar confirmación de propina por SMS"])},
    "disable-payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deshabilitar el pago automático"])},
    "user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Función del usuario"])},
    "show-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar informes de empleados"])}
  },
  "qr-codes": {
    "can-not-confirm-assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, no hemos podido confirmar tu asignación"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear código QR"])},
    "statuses": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
      "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignado"])},
      "not-assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No asignado"])},
      "waiting-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperando confirmación"])}
    },
    "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se te ha desasignado"])}
  },
  "reports": {
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empleados"])},
    "empty": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intenta modificar tu búsqueda o selecciona otro informe"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todavía no hay nada aquí"])}
    },
    "filter": {
      "datepicker": {
        "last-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El mes pasado"])},
        "last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La semana pasada"])},
        "last-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El año pasado"])}
      },
      "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de finalización"])},
      "max-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad máxima"])},
      "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])}
    },
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis organizaciones"])},
    "outline-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos extraordinarios"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos"])},
    "recent-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empleados recientes"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar informe"])},
    "save-as": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Guardar como ", _interpolate(_named("format"))])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Informe"]), _normalize(["Informes"])])},
    "teams-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipo"])},
    "team-pooling-results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Pooling Results"])},
    "registration-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Date"])},
    "paid-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL PAGADO"])},
    "paid-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAGADO"])},
    "cumBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUM.BALANCE"])}
  },
  "table": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos"])}
  },
  "team": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estadísticas"])},
    "create-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear equipo"])},
    "delete-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar equipo"])},
    "edit-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar equipo"])},
    "employees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Empleado"]), _normalize(["Empleados"])])},
    "group-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código QR del grupo"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto responsable"])},
    "team-exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El equipo con nombre ", _interpolate(_named("name")), " ya existe"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Equipo"]), _normalize(["Equipos"])])},
    "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "showTeamName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestre el nombre del equipo en lugar del nombre de usuario en la página de pago."])},
    "paypal-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Desactivar el botón de PayPal (y Klarna) cuando las propinas sean menos de ", _interpolate(_named("threshold")), " euro."])},
    "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has sido no asignado"])}
  },
  "top-bar": {
    "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrupación"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar organizaciones, equipos o código QR"])},
    "search-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])}
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Usuario"]), _normalize(["Usuarios"])])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los equipos"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipo"])}
  },
  "pwa-link": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tippie to Home screen"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippie added to home screen"])},
    "get-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar la aplicación gratuita"])}
  },
  "faq": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flossplatz 6, 04107 Leipzig, Alemania"])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copyright © ", _interpolate(_named("date")), ". Reservados todos los derechos."])}
  },
  "generate-signup-link": {
    "iban-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con IBAN"])},
    "iban-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin IBAN"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empleado"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dueño"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡El enlace de registro se ha copiado correctamente!"])}
  }
}