<template>
  <div>
    <router-view :key="$route.path" />
    <div v-if="showSnackBar" class="snackbar">
      <h3>
        {{ $t("reset-cache.title") }}
        <el-button type="text" @click="reloadPage">{{
          $t("reset-cache.button")
        }}</el-button>
      </h3>
    </div>
    <div v-if="showAddToHomeButton" class="add-home-screen">
      <img
        src="@/assets/img/logo.png"
        class="add-home-screen__logo"
        alt="Tippie App"
      />
      <div class="add-home-screen-info">
        <div class="app-description">
          <h4>Tippie</h4>
          <p>GSI Verwaltung GmbH</p>
          <b>{{ $t("pwa-link.get-app") }}</b>
        </div>
        <a :href="appLink" class="add-home-screen__button">{{
          $t("form.view")
        }}</a>
      </div>
      <div class="add-home-screen__close">
        <el-icon :size="25" @click="hideHomeScreenButton">
          <CloseBold color="#797878" />
        </el-icon>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { useCookies } from "vue3-cookies";
import isWebview from "is-ua-webview";
import detectOSByBrowser from "@/shared/detect-os.js";

const { cookies } = useCookies();
export default {
  data: () => ({
    showSnackBar: false,
    deferredPrompt: undefined,
    showAddToHomeButton: false,
    appLink: process.env.VUE_APP_ANDROID_APP,
  }),
  created() {
    if (cookies.get("locale")) {
      this.$i18n.locale = cookies.get("locale");
    } else if (navigator.language.split("-")[0] === "de") {
      this.$i18n.locale = "de";
    } else {
      this.$i18n.locale = "en";
    }
    document.addEventListener(
      "serviceWorkerUpdateEvent",
      () => {
        this.showSnackBar = true;
      },
      { once: true }
    );
  },
  mounted() {
    this.appLink = ["Macintosh", "iOS"].includes(detectOSByBrowser())
      ? process.env.VUE_APP_APPLE_APP
      : process.env.VUE_APP_ANDROID_APP;
    this.addToHomeScreen();
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
    addToHomeScreen() {
      if (
        !isWebview(window.navigator.userAgent) &&
        !cookies.isKey("hideAppBottomPanel")
      ) {
        this.showAddToHomeButton = true;
      }
    },
    addToHomeScreenClicked() {
      this.hideHomeScreenButton();
    },
    hideHomeScreenButton() {
      cookies.set("hideAppBottomPanel", true, "24h");
      this.showAddToHomeButton = false;
    },
  },
};
</script>

<style lang="scss">
.snackbar {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  padding: 15px;
  border: 1px solid #e3e5e5;
  border-radius: 8px;
  z-index: 999;
}

.add-home-screen {
  display: none;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  background: #ffffff;
  padding: 0 10px;
  box-shadow: 0 0 10px rgba(57, 20, 0, 0.04);
  border-top: 1px solid rgba(57, 20, 0, 0.04);
  z-index: 99;

  @media (max-width: 992px) {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;
  }

  .app-description {
    h4 {
      font-weight: bold;
      line-height: 1.5;
    }

    p {
      font-size: 12px;
      color: #797878;
    }

    b {
      font-size: 12px;
    }
  }

  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    line-height: 1.25;
  }

  &__logo {
    height: 40px;
  }

  &__button {
    font-family: "Raleway Medium", serif;
    font-size: 16px;
    text-decoration: none;
    color: #ff8a00;
    margin-left: 15px;
    position: absolute;
    right: 70px;
    font-weight: bold;

  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 16px;
  }
}
</style>
