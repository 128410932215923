import { adminAPI } from "@/plugins/axios";

export default class AdminAPIService {
  constructor() {
    this.endpoint = "/api";
    this.methods = {
      DELETE: 'delete',
      GET: 'get',
      POST: 'post',
      PUT: 'put',
      PATCH: 'patch'
    }
  }

  async apiCall({ method, url, payload = {} }) {
    try {
      const { data, headers } = await adminAPI[method](`${this.endpoint}${url}`, payload);
      // TODO: Implement valid response code on backend
      if(data?.status && data.status >= 400) {
        // error
        return [ data ];
      }
      return [ null, data, headers ];
    } catch (error) {
      return [ error ];
    }
  }
}