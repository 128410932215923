export default {
  "menu": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üye Ol"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgeler"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destek"])}
  },
  "auth": {
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "forgot-pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolanızı mı unuttunuz?"])},
    "generate-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola oluştur"])},
    "reset-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolayı Sıfırla"])},
    "sign-in": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum Aç"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturum açarak şunları kabul etmiş olursunuz:"])}
    },
    "sign-out": {
      "confirm-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumu kapatmak istediğinizden emin misiniz?"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oturumu kapat"])}
    },
    "sign-up": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üye Ol"])},
      "user-terms-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir hesap oluşturarak şunu kabul etmiş olursunuz:"])}
    },
    "user-terms-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hüküm ve koşullar"])}
  },
  "error-page": {
    "can-not-find-page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aradığınız sayfayı bulamıyoruz."])}
  },
  "form": {
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ata"])},
    "back-to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana sayfaya geri dön"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onayla"])},
    "confirm-unassign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gerçekten şirketle olan bağlantınızı kesmek istiyor musunuz ", _interpolate(_named("company")), "?"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sil"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayrıntılar"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitti"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Düzenle"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])},
    "item-approved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " onaylandı"])},
    "item-assigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " atandı"])},
    "item-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " değiştirildi"])},
    "item-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " oluşturuldu"])},
    "item-deleted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " silindi"])},
    "item-not-changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Üzgünüz, ", _interpolate(_named("item")), " öğesini değiştiremiyoruz"])},
    "item-not-created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Üzgünüz, ", _interpolate(_named("item")), " öğesini oluşturamıyoruz"])},
    "item-not-found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " bulunamadı"])},
    "item-unassigned": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("item")), " ataması kaldırıldı"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İleri"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sıfırla"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönder"])},
    "something-wrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne yazık ki bir sorun oluştu"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başarılı"])},
    "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamayı kaldır"])},
    "validation": {
      "all-items-populated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm öğeler doldurulmalıdır"])},
      "avatar-format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avatar resmi ", _interpolate(_named("format")), " biçiminde olmalıdır"])},
      "avatar-size": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avatar resim boyutu ", _interpolate(_named("size")), " değerini aşamaz."])},
      "dont-much": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " eşleşmiyor"])},
      "field-max-value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), ", ", _interpolate(_named("max")), " değerinden büyük olamaz"])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geçersiz ", _interpolate(_named("field")), " biçimi"])},
      "incorrect": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Yanlış ", _interpolate(_named("field"))])},
      "input-again": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lütfen ", _interpolate(_named("field")), " girişini tekrar yapın"])},
      "must-be-number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " bir sayı olmalıdır"])},
      "one-item-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En az bir öğe varsayılan olmalıdır"])},
      "one-item-filled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En az bir alan doldurulmalıdır"])},
      "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("field")), " gereklidir"])},
      "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalan tutar 0 olmalıdır"])}
    },
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüntüle"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evet"])}
  },
  "opera": {
    "payment-methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mevcut Ödeme Yöntemleri"])}
  },
  "presentation": {
    "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunu Modu"])}
  },
  "fee": {
    "tippie-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konuk ödemezse Tippie ücreti öder"])},
    "organization-pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garson her zaman ücret öder"])},
    "amount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " hizmet ücreti ", _interpolate(_named("icon"))])},
    "checkbox": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("nickname")), "'in bahşişinizi eksiksiz olarak almasına yardımcı olun"])},
    "dialog-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemle ilgili maliyetler söz konusu olduğundan küçük bir işlem ücreti kesiyoruz. Bu maliyetleri, bahşişinizin bir parçası olarak karşılamaya katkıda bulunarak, bahşiş alıcısının tutarı eksiksiz olarak almasını sağlarsınız."])},
    "dialog-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapat"])}
  },
  "organization": {
    "form": {
      "tabs": {
        "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genel"])},
        "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirim"])},
        "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiyatlandırma"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayarlar"])}
      }
    },
    "wanna-unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamayı kaldırmak istediğinizden emin misiniz?"])},
    "industry": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sektör"])},
      "options": {
        "charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hayır kurumu"])},
        "barber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuaför"])},
        "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otel"])},
        "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restoran"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diğer"])}
      }
    },
    "hide-paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide PayPal button if payer has Apple Pay or Google Pay"])},
    "be-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BE KİMLİĞİ"])},
    "create-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuruluş oluştur"])},
    "defined-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceden seçilmiş olan bahşiş tutarı"])},
    "defined-percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makbuz tutarından önceden seçilmiş bahşiş yüzdesi"])},
    "delete-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silmek istediğinize emin misiniz?"])},
    "edit-organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuruluşu düzenle"])},
    "feedback-enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geri bildirimi/değerlendirmeyi etkinleştir"])},
    "google-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google İncelemesi etkinleştirildi"])}
    },
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuruluşlarım"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ad"])},
    "platform-fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform Ücreti %"])},
    "pre-selected-tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Önceden seçilmiş bahşiş"])},
    "qr-code-ranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qr Kodu Aralıkları"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temsilci kişi"])},
    "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepsini gör"])},
    "send-payment-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme onayı e-postası gönderilsin mi?"])},
    "tax-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergi Kimlik Numarası"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kuruluş"]), _normalize(["Kuruluşlar"])])},
    "trial-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deneme Süresi Sonu"])},
    "tripadvisor-review": {
      "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tripadvisor İncelemesi etkinleştirildi"])}
    },
    "type-it-system": {
      "options": {
        "generated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturuldu"])},
        "plastic-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastik kart"])},
        "stickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çıkartmalar"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BT sisteminin türü"])}
    },
    "type-sharing-tips": {
      "options": {
        "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bireysel"])},
        "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havuz oluşturma"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahşiş paylaşımı türü"])}
    },
    "pricingPayerPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing if payer pays fee"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed fee in EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage fee above threshold"])}
    },
    "pricingRecipientPaysFee": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing if recipient pays fee"])},
      "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed fee in EUR"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage fee above threshold"])}
    },
    "pricingPayout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing for payout"])},
      "threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Threshold for free payout in EUR"])},
      "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee for payout below threshold in  EUR"])}
    }
  },
  "pay-out": {
    "no-iban-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banka hesabınızdan para çekmeden önce hesap bilgilerinizi girmeniz gerekmektedir. Bunu yapmak için lütfen uygulamadaki \"Profili düzenle\" seçeneğine tıklayın."])},
    "bank-statement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lütfen banka ekstrenizin parayı göndereni <b>\"tippie\"</b> veya <b>\"Stripe\"</b> veya <b>\"NaudaPay Limited\"</b> olarak göstereceğini unutmayın."])},
    "payout-fee": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bu işlem için transfer ücreti ", _interpolate(_named("payoutFee"))])},
    "payout-fee-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("payoutFeeThreshold")), " ve üzeri çekimlerde ücret alınmaz."])},
    "check-payout-threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eşikte otomatik olarak ödeme yapın"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme yap"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutar"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN'a havaleyi onayla"])},
    "equal-to-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkese eşit"])},
    "equal-to-remain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalana eşit"])},
    "error-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata! İşlem gerçekleştirilemedi. Lütfen Tippie desteğiyle iletişime geçin"])},
    "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yüzdeler"])},
    "residual-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalan tutar"])},
    "residual-percents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kalan yüzdeler"])},
    "strategy": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutabakat Stratejisi"])},
      "types": {
        "complex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karmaşık"])},
        "simple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basit"])}
      }
    },
    "success-transaction-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teşekkürler! Birkaç dakika içinde bir onay e-postası alacaksınız."])},
    "total-pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam ödeme"])},
    "to-reconcile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutabakat sağlanacak tutar"])}
  },
  "profile": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamalar"])},
    "balance": {
      "balance-of-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesabınızın bakiyesi:"])},
      "tips-last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son 7 gün içinde alınan bahşişler"])},
      "tips-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugün alınan bahşişler"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bakiye"])},
      "transfer-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banka hesabına havale tutarı:"])},
      "transfer-to-iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN'a havale"])},
      "your-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bakiyeniz"])},
      "reconciled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahşişler banka hesabınıza aktarıldı:"])},
      "inTransition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahşişler banka hesabınıza aktarım sürecinde:"])}
    },
    "change-image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resmi Değiştir"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolayı Değiştir"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şehir"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ülke"])},
    "dob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doğum Tarihi"])},
    "edit-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profili Düzenle"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posta"])},
    "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adı"])},
    "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "iban-connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN'ınız bağlandı"])},
    "iban-contact-support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN ile ilgili sorunu çözmek için desteğe başvurun"])},
    "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyadı"])},
    "motivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerekçe"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Parola"])},
    "nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumuz"])},
    "notification-emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildirimler e-postaya gönderilsin mi?"])},
    "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eski Parola"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola"])},
    "password-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola en az 8 karakter uzunluğunda olmalı ve en az bir küçük harf, bir büyük harf, bir sayı ve bir özel karakter içermelidir. "])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posta kodu"])},
    "repeat-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni Parolayı Tekrar Girin"])},
    "repeat-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parolayı tekrar girin"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ev"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "send-tips-sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahşiş onayını SMS ile gönder"])},
    "disable-payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otomatik ödemeyi devre dışı bırak"])},
    "user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanıcı rolü"])},
    "show-reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışan raporlarını göster"])}
  },
  "qr-codes": {
    "can-not-confirm-assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzgünüz, atamanızı onaylayamadık"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Kodu Oluştur"])},
    "statuses": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hepsi"])},
      "assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atandı"])},
      "not-assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atanmadı"])},
      "waiting-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onay bekleniyor"])}
    },
    "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamanız kaldırıldı"])}
  },
  "reports": {
    "employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışanlar"])},
    "empty": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aramanızı değiştirmeyi deneyin veya başka bir rapor seçin"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burada henüz bir şey yok"])}
    },
    "filter": {
      "datepicker": {
        "last-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçen ay"])},
        "last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçen hafta"])},
        "last-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geçen yıl"])}
      },
      "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitiş tarihi"])},
      "max-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimum tutar"])},
      "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Başlangıç tarihi"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])}
    },
    "my-organizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuruluşlarım"])},
    "outline-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uç Değerli Ödemeler"])},
    "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödemeler"])},
    "recent-employees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yakın Zamandaki Çalışanlar"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporu kaydet"])},
    "save-as": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("format")), " olarak kaydet"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Rapor"]), _normalize(["Raporlar"])])},
    "paid-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PARALI"])},
    "paid-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÖDENDİ"])},
    "cumBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUM.BALANCE"])}
  },
  "table": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veri bulunmuyor"])}
  },
  "team": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İstatistik"])},
    "create-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekip Oluştur"])},
    "delete-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekibi Sil"])},
    "edit-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekibi düzenle"])},
    "employees": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Çalışan"]), _normalize(["Çalışanlar"])])},
    "group-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grup QR Kodu"])},
    "resp-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorumlu kişi"])},
    "team-exist": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " adlı ekip zaten mevcut"])},
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ekip"]), _normalize(["Ekipler"])])},
    "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toplam"])},
    "showTeamName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme sayfasında kullanıcı adı yerine takım adını gösterin."])},
    "paypal-threshold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bahşiş ", _interpolate(_named("threshold")), " EUR'dan az ise PayPal (ve Klarna) düğmesini devre dışı bırakın."])},
    "unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atamanız kaldırıldı"])}
  },
  "top-bar": {
    "pooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havuz oluşturma"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuruluş, ekip, veya QR kodu ara"])},
    "search-mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ara"])}
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Kullanıcı"]), _normalize(["Kullanıcılar"])])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bireysel"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Takımlar"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takım"])}
  },
  "reset-cache": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yeni içerik mevcut"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yenile"])}
  },
  "pwa-link": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Tippie to Home screen"])},
    "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tippie added to home screen"])},
    "get-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ücretsiz uygulamayı indirin"])}
  },
  "faq": {
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flossplatz 6, 04107 Leipzig, Almanya"])},
    "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Telif hakkı © ", _interpolate(_named("date")), ". Her hakkı saklıdır."])}
  },
  "generate-signup-link": {
    "iban-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN'lı"])},
    "iban-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN'sız"])},
    "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çalışan"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mal sahibi"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kayıt bağlantısı başarıyla kopyalandı!"])}
  }
}