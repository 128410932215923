import AdminAPIService from "./AdminAPIService";
import normalazieUser from "../../shared/normalizeUser";
import { Auth } from "@aws-amplify/auth";

/* 
    @param id: sub | username
  */

export default class UsersService extends AdminAPIService {
  constructor() {
    super();
    this.userGroups = {
      admin: "admin-group",
      team: "team-representative",
      business: "business-representative",
      employee: "employee",
    };
  }

  async getCurrentUserID() {
    return await Auth.currentAuthenticatedUser().then(
      (currentUser) => currentUser.attributes.sub
    );
  }

  async getCurrentUser() {
    return await Auth.currentAuthenticatedUser().then(async (currentUser) => {
      const [error, user] = await this.getUser(currentUser.attributes.sub);

      if (error) {
        return [error];
      }

      return [null, normalazieUser(user)];
    });
  }

  getUserGroup(group = null) {
    if (this.userGroups[group] == null) {
      console.error("User group doesn't exist");
      throw new Error("User group doesn't exist");
    }

    return this.userGroups[group];
  }

  getUser(id = null) {
    return this.apiCall({
      method: this.methods.GET,
      url: `/v2/employees/${id}`,
    });
  }

  getUserAssignments(id = null) {
    if(!id) return []

    return this.apiCall({
      method: this.methods.GET,
      url: `/v1/employees/${id}`,
    });
  }

  getUserBalances(id = null) {
    return this.apiCall({
      method: this.methods.GET,
      url: `/v1/employees/${id}/balance`,
    });
  }

  getUsers(search = "", payload = {}) {
    let q = search ? `&search=${search}` : "";

    return this.apiCall({
      method: this.methods.GET,
      url: `/v2/employees?&per_page=20${q}`,
      payload,
    });
  }

  deleteUser(id = null) {
    return this.apiCall({
      method: this.methods.DELETE,
      url: `/v2/employees/${id}`,
    });
  }

  updateUser(payload = {}, id = null) {
    return this.apiCall({
      method: this.methods.PATCH,
      url: `/v1/employees/${id}`,
      payload,
    });
  }

  updateCognitoUser(payload = {}, id = null) {
    return this.apiCall({
      method: this.methods.PATCH,
      url: `/v2/employees/${id}`,
      payload,
    });
  }

  removeUserFromGroup(groupName = {}, id = null) {
    return this.apiCall({
      method: this.methods.DELETE,
      url: `/v1/employees/${id}/groups/${this.getUserGroup(groupName)}`,
    });
  }

  addUserToGroup(groupName = {}, id = null) {
    return this.apiCall({
      method: this.methods.POST,
      url: `/v1/employees/${id}/groups/${this.getUserGroup(groupName)}`,
    });
  }

  getUsersByGroup(groupName = {}, payload = {}) {
    return this.apiCall({
      method: this.methods.GET,
      url: `/v1/employees/groups/${this.getUserGroup(groupName)}?per_page=60`,
      payload,
    });
  }

  addDeviceToken(payload = {}) {
    return this.apiCall({
      method: this.methods.POST,
      url: `/v1/device-token`,
      payload,
    });
  }

  getNotifications() {
    return this.apiCall({
      method: this.methods.GET,
      url: `/v1/pushes`,
    });
  }
}

export const usersAPI = new UsersService();
