import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import {router, routerSetup } from './router';
import i18n from './plugins/i18n';
import { createPinia } from 'pinia'
import VueGtag from "vue-gtag";

// Element UI
import ElementPlus from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// Main stylesheet
import './styles/main.scss';

// AWS Libraries
import AwsAmplify from './plugins/aws/amplify';

//Axios
import axios from './plugins/axios';

// Push notifications
import { requestPermission, deleteFCMToken} from './plugins/firebase-fsm';

// Phone input field
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

// Vue Cookies
import { globalCookiesConfig } from 'vue3-cookies';

//Repeater
import VueRepeater from '/src/plugins/repeater';

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

globalCookiesConfig({
  expireTimes: '30d',
  path: '/',
  domain: '',
  secure: true,
  sameSite: 'None'
});

const VueTelInputOptions = {
  defaultCountry: 'DE',
  dropdownOptions: {
    showDialCodeInSelection: true
  },
  inputOptions: {
    autofocus: true,
    placeholder: '(000) 000-00-00',
    required: true
  },
  mode: 'international',
  validCharactersOnly: true,
  styleClasses: 'custom-phone-input'
};

const app = createApp(App);

app.config.globalProperties.$requestPermission = requestPermission;
app.config.globalProperties.$deleteFCMToken = deleteFCMToken;

//Register Element UI Icons
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

const pinia = createPinia()

app.use(i18n)
  .use(pinia)
  .use(routerSetup)
  .use(VueGtag, {
    config: { id: "G-F3VKNTHGCV" }
  }, router)
  .use(ElementPlus, {
    i18n: (key, value) => i18n.t(key, value)
  })
  .use(AwsAmplify)
  .use(VueTelInput, VueTelInputOptions)
  .use(VueRepeater)
  .use(axios)
  .use(PerfectScrollbar);


app.mount('#app');
