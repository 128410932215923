class Logger {
  constructor() {}

  static async send(config = {}) {
    const { baseURL, data, url } = config;
    fetch(process.env.VUE_APP_LOGGER_URL, {
      method: "POST",
      body: JSON.stringify({
        baseURL,
        data,
        url,
        env: process.env.NODE_ENV,
      }),
    });
  }
}

export default Logger;
