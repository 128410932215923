<template>
  <div class="top-section-collapse">
    <div class="top-section-collapse-row">
      <label class="top-section-collapse-row__label">Title</label>
      <el-input v-model="collapseItem.title" />
    </div>
    <div class="top-section-collapse-row">
      <label class="top-section-collapse-row__label">Content</label>
      <vue-editor v-model="collapseItem.content" />
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue3-editor';

export default {
  name: 'TopSectionCollapse',
  components: {
    VueEditor
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  computed: {
    collapseItem: {
      get: function () {
        return this.modelValue;
      },
      set: function (value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.top-section-collapse {
  &-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &__label {
      font-family: "Raleway SemiBold", serif;
      font-size: 16px;
      color: #2D2E2F;
    }
  }
}
</style>
