import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  deleteToken,
  onMessage,
} from "firebase/messaging";
import { ElNotification } from "element-plus";
import { usersAPI } from "@/shared/services/users.service";
import isWebview from "is-ua-webview";

export let app = null
export let messaging = null

if(!isWebview(window.navigator.userAgent)) {
  const firebaseConfig = {
    apiKey: "AIzaSyCLHLFK_b-8lXVnqdUke_sFq-ILZ9UVIWw",
    authDomain: "tippie-156e1.firebaseapp.com",
    projectId: "tippie-156e1",
    storageBucket: "tippie-156e1.appspot.com",
    messagingSenderId: "180107421673",
    appId: "1:180107421673:web:9beec5054155d6ecdb8d78",
  };

  app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);

  if (navigator.serviceWorker && window.PushManager && window.Notification) {
    onMessage(messaging, (payload) => {
      ElNotification({
        title: payload.notification.title,
        message: payload.notification.body,
        type: "success",
        duration: 1000 * 3600,
      });
    });
  }
}

// Request Permission of Notifications
export async function requestPermission() {
  return Notification.requestPermission()
    .then(async (permission) => {
      if (permission === "granted") {
        window.localStorage.setItem("push-msg-granted", true);
        getFCMToken();
      } else {
        window.localStorage.setItem("push-msg-granted", false);
      }
    })
    .catch((err) => {
      console.log("Unable to get permission to notify.", err);
    });
}

export async function deleteFCMToken(msg) {
  await deleteToken(msg);
}

async function getFCMToken() {
  await getToken(messaging, {
    vapidKey: process.env.VUE_APP_FIREBASE_PUBLIC_VAPID,
  })
    .then((currentToken) => {
      if (currentToken) {
        window.localStorage.setItem("push-msg-token", currentToken);
        usersAPI.addDeviceToken({
          device: "Web",
          deviceToken: currentToken,
        });
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
}
