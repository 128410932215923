import { Storage } from "@aws-amplify/storage";
import { Auth } from "@aws-amplify/auth";


const amplifyConfig = {
  Auth: {
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
    region: process.env.VUE_APP_AWS_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID
  },
  Storage: {
    AWSS3: {
      bucket: process.env.VUE_APP_S3_BUCKET,
      region: process.env.VUE_APP_AWS_REGION
    }
  }
};
export default {
  install (app) {
    Storage.configure(amplifyConfig.Storage);
    Auth.configure(amplifyConfig.Auth);
    app.config.globalProperties.getCurrentUser = () => {
      return Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then((user) => {
        return user.attributes;
      });
    };
    app.config.globalProperties.isCurrentUserAdmin = () => {
      return Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then((user) => {
        const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
        return groups.includes('admin-group');
      });
    };
    app.config.globalProperties.currentUserRoles = () => {
      return Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then((user) => user.signInUserSession.accessToken.payload['cognito:groups'] || []);
    };
    app.config.globalProperties.isCurrentUserBusiness = () => {
      return Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then((user) => {
        const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
        return groups.includes('business-representative');
      });
    };
    app.config.globalProperties.isCurrentUserTeamResp = () => {
      return Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then((user) => {
        const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
        return groups.includes('team-representative');
      });
    };
    app.config.globalProperties.isCurrentUserEmployee = () => {
      return Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then((user) => {
        const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
        return groups.includes('employee');
      });
    };
    app.config.globalProperties.isCurrentUserHasIban = () => {
      return Auth.currentAuthenticatedUser({
        bypassCache: true,
      }).then((user) => {
        return !!(user.attributes['custom:iban'] && user.attributes['custom:iban'].length);
      });
    };
  }
};
